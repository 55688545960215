<template>
  <v-card
    flat
    tile
    ripple
    :class="last ? '' : 'border-bottom'"
    @click="$emit('click')"
  >
    <v-card-text>
      <v-row no-gutters align="center">
        <v-col cols="3" class="subtitle-2 text-truncate">
          <v-avatar
            size="32"
            :color="authorized ? 'green darken-1' : 'red darken-1'"
          >
            <v-icon dark>{{
              authorized ? "mdi-account-check" : "mdi-account-off"
            }}</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="3" class="title text-truncate grey--text text--darken-3">{{
          client.table.number
        }}</v-col>

        <v-col cols="6" class="key red--text text--darken-2 text-right">{{
          client.key
        }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    last: Boolean,
    client: Object,
  },

  computed: {
    authorized() {
      return (
        this.client.authorized_by != null && this.client.authorized_at != null
      );
    },
  },
};
</script>

<style></style>
