<template>
  <v-main>
    <v-container fluid>
      <h3 class="headline mb-4">{{ $t('clients.title') }}</h3>

      <v-card>
        <v-card-text>
          <v-row>
            <!-- Filtro por numero de mesa -->
            <v-col cols="12" md="6" class="py-0">
              <v-select
                v-model="filter.tables"
                :items="tables"
                label="Número de mesa"
                item-value="uuid"
                item-text="number"
                prepend-icon="mdi-table-chair"
                deletable-chips
                multiple
                chips
                small-chips
              ></v-select>
            </v-col>

            <!-- Filtro por estado del pedido -->
            <v-col cols="12" md="6" class="py-0">
              <v-select
                v-model="filter.status"
                :items="statusesList"
                label="Estado del pedido"
                item-value="id"
                item-text="value"
                prepend-icon="mdi-chart-bubble"
              ></v-select>
            </v-col>

            <!-- Buttons -->
            <v-col cols="12" class="text-right">
              <v-btn @click="reset" class="ma-2">Limpiar filtros</v-btn>
              <!-- FIXME: Traduccion del boton -->
              <v-btn color="primary" @click="search(1)" class="ma-2">
                Buscar
              </v-btn>
            </v-col>
          </v-row>

          <!-- Clients table -->
          <!-- FIXME: Traduccion de las cabeceras -->
          <v-card flat tile class="border-bottom" v-if="clients.length > 0">
            <v-card-text>
              <v-row no-gutters class="grey--text text--darken-4">
                <v-col cols="3" class="subtitle-1 text-truncate">Auth</v-col>
                <v-col cols="3" class="subtitle-1 text-truncate">Mesa</v-col>
                <v-col cols="6" class="subtitle-1 text-right">Key</v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <easy-serve-client
            v-for="(client, index) in clients"
            :key="'client_' + client.id"
            :client="client"
            :last="clients.length - 1 == index"
            @click="showDialog(client)"
          ></easy-serve-client>
          <!-- Clients table -->
        </v-card-text>
      </v-card>

      <v-alert
        type="info"
        border="left"
        class="my-4"
        colored-border
        elevation="2"
        :value="clients.length == 0 && !loading"
        >{{ $t('clients.noResults') }}</v-alert
      >

      <v-row>
        <v-col cols="12">
          <div class="text-right my-4">
            <v-pagination
              v-model="page"
              :length="last_page"
              :total-visible="3"
              @input="search"
            ></v-pagination>
          </div>
        </v-col>
        <!-- End pagination -->
      </v-row>
    </v-container>

    <!-- Edit dialog -->
    <easy-serve-client-dialog
      v-if="client"
      :dialog="dialog"
      :client="client"
      :tables="tables"
      @close="closeDialog"
      @save="saveClient"
    ></easy-serve-client-dialog>
    <!-- Edit dialog -->

    <easy-serve-loading :loading="loading"></easy-serve-loading>
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { mapGetters } from 'vuex';
import EasyServeClient from './components/EasyServeClient';
import EasyServeClientDialog from './components/EasyServeClientDialog';
import EasyServeLoading from '../components/EasyServeLoading';

export default {
  mixins: [errors],

  components: {
    EasyServeClient,
    EasyServeClientDialog,
    EasyServeLoading,
  },

  data: () => ({
    page: null,

    client: null,
    dialog: false,

    filter: {
      tables: [],
      status: '',
    },
  }),

  computed: {
    ...mapGetters('restaurant', {
      restaurant: 'restaurant', // Needed for the title
    }),

    ...mapGetters('clients', {
      clients: 'clients',
      last_page: 'last_page',
      current_page: 'current_page',
      loading: 'loading',
    }),

    ...mapGetters('security', {
      isClient: 'isClient',
      isUser: 'isUser',
      tables: 'tables',
    }),

    statusesList() {
      return [
        {
          id: null,
          value: this.$t('clients.statuses.any'),
        },
        {
          id: 'authorized',
          value: this.$t('clients.statuses.authorized'),
        },
        {
          id: 'unauthorized',
          value: this.$t('clients.statuses.notAuthorized'),
        },
      ];
    },
  },

  watch: {
    $route() {
      this.load();
    },
  },

  created() {
    if (this.isClient) {
      return this.$router.push({ name: 'error440' });
    }

    // Set the current page if there is one set
    this.page = this.currentPage();

    // Update the filter from the route
    this.filter.tables = this.currentFilterTables();
    this.filter.status = this.currentFilterStatus();

    this.load();
  },

  methods: {
    load() {
      const uuid = this.restaurant.uuid
        ? this.restaurant.uuid
        : localStorage.getItem('restaurant_uuid');

      // Create the payload
      const payload = {
        restaurant: uuid,
        filter: this.filter,
        page: this.page,
      };

      this.$store
        .dispatch('clients/load', payload)
        .then(results => {
          // Clear all errors
          this.clearErrors();

          if (this.page > results.data.last_page) {
            // Reload
            this.search(results.data.last_page);
          }
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    currentPage() {
      if (isNaN(this.$route.query.page)) {
        return 1;
      } else {
        return parseInt(this.$route.query.page);
      }
    },

    search(page = null) {
      // Filter the orders
      this.$router
        .push({
          name: 'clients',
          params: {
            restaurant: this.restaurant.uuid,
          },
          query: this.query(page),
        })
        // We need to ignore the error (same route)
        .catch(() => {});
    },

    reset() {
      this.filter.tables = [];
      this.filter.status = '';
    },

    query(page = null) {
      if (page) this.page = page;

      let query = {
        page: this.page,
      };

      // Check all filters
      if (this.filter.tables.length > 0)
        query.tables = this.filter.tables.join(',');
      if (this.filter.status !== null) query.status = this.filter.status;

      return query;
    },

    showDialog(client) {
      this.client = client;
      this.dialog = true;
    },

    closeDialog() {
      this.client = null;
      this.dialog = false;
    },

    saveClient(event) {
      // Change the table or the authorization
      const uuid = this.restaurant.uuid
        ? this.restaurant.uuid
        : localStorage.getItem('restaurant_uuid');

      const payload = {
        restaurant: uuid,
        client: this.client.id,

        table_uuid: event.table,
        authorize: event.checkbox,
      };

      this.client = null;
      this.dialog = false;

      this.$store
        .dispatch('clients/update', payload)
        .then(() => {
          // Reload all data
          this.load();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    /// FILTERS
    currentFilterTables() {
      let tables = this.$route.query.tables;
      if (!tables) {
        return [];
      }

      // Get all tables (tables=uuid,uuid,uuid)
      tables = tables.split(',');
      let filterTables = [];

      // Lets check the allowed values
      this.tables.forEach(t => {
        if (tables.indexOf(t.uuid) > -1) {
          filterTables.push(t.uuid);
        }
      });

      return filterTables;
    },

    currentFilterStatus() {
      let status = this.statusesList.find(
        s => s.id == this.$route.query.status
      );
      return status ? status.id : null;
    },
  },
};
</script>

<style></style>
