<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Datos cliente</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5">
                <span class="body-1 font-weight-bold">Creación:</span>
              </v-col>
              <v-col cols="7">
                <v-icon class="mr-3">mdi-history</v-icon>
                <span>{{ createdTime }}</span>
              </v-col>

              <v-col cols="5">
                <span class="body-1 font-weight-bold">Expiración:</span>
              </v-col>
              <v-col cols="7">
                <v-icon class="mr-3">mdi-history</v-icon>
                <span>{{ expirationTime }}</span>
              </v-col>

              <v-col cols="5">
                <span class="body-1 font-weight-bold">Código:</span>
              </v-col>
              <v-col cols="7">
                <v-icon class="mr-3">mdi-lock-open-outline</v-icon>
                <span class="font-weight-bold red--text text--darken-2">{{
                  client.key
                }}</span>
              </v-col>

              <v-col cols="5" v-if="authorized">
                <span class="body-1 font-weight-bold">Autorizado:</span>
              </v-col>
              <v-col cols="7" v-if="authorized">
                <v-icon class="mr-3">mdi-history</v-icon>
                <span>{{ authorizationTime }}</span>
              </v-col>

              <v-col cols="5" v-if="authorized">
                <span class="body-1 font-weight-bold">Camarero:</span>
              </v-col>
              <v-col cols="7" v-if="authorized">
                <v-icon class="mr-3">mdi-account-circle</v-icon>
                <span>{{ authorizationWaiter }}</span>
              </v-col>

              <v-col cols="12" class="pb-0">
                <v-select
                  :items="tables"
                  item-value="uuid"
                  item-text="number"
                  v-model="table"
                  label="Mesa*"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-checkbox v-model="checkbox" label="Autorizado"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="$emit('close')"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    dialog: Boolean,
    client: {},
    tables: Array,
  },

  data: () => ({
    checkbox: false,
    table: null,
  }),

  created() {
    this.checkbox = this.authorized;
    this.table = this.client.table.uuid;
  },

  computed: {
    authorized() {
      return !!(this.client.authorized_at && this.client.authorized_by);
    },

    createdTime() {
      return dayjs(this.client.created_at).format("HH:mm:ss");
    },

    expirationTime() {
      return dayjs(this.client.expires_at).format("HH:mm:ss");
    },

    authorizationTime() {
      return dayjs(this.client.authorized_at).format("HH:mm:ss");
    },

    authorizationWaiter() {
      if (this.client.authorized_by)
        return this.client.authorized_by.first_name;
      return "No autorizado";
    },
  },

  methods: {
    save() {
      const payload = {
        checkbox: this.checkbox,
        table: this.table,
      };

      // Check if the data has changed
      if (
        payload.checkbox != this.authorized ||
        payload.table != this.client.table.uuid
      ) {
        return this.$emit("save", payload);
      }

      // The data was not modified
      this.$emit("close");
    },
  },
};
</script>

<style></style>
